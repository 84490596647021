<template>
  <div class="useRegistration">
    <van-nav-bar title="设备使用记录" left-text="返回" @click-left="back()" left-arrow style="z-index:1000" />
    <van-dropdown-menu>
      <van-dropdown-item :title="`开始日期：${starDate}`" ref="starCustom">
        <van-datetime-picker type="date" title="选择开始时间" v-model="starCurrentDate"
          @confirm="(date) => dataStartChange(date, 'star', 'starCustom')"
          @cancel="closeDataStartChange('starCustom')" />
      </van-dropdown-item>
      <van-dropdown-item :title="`结束日期：${endDate}`" ref="endCustom">
        <van-datetime-picker type="date" title="选择结束时间" v-model="endCurrentDate"
          @confirm="(date) => dataStartChange(date, 'end', 'endCustom')"
          @cancel="closeDataStartChange('endCustom')" />
      </van-dropdown-item>
    </van-dropdown-menu>

    <!-- 主要内容 -->
    <div class="primaryCoverage">
      <div class="primaryCoverageItem" v-for="(item, index) in historicalRecordsList" :key="index">
        <van-row>
          <van-col span="6" class="primaryLabel">设备编码</van-col>
          <van-col span="18">{{ item.deviceCode }}</van-col>
        </van-row>
        <van-row>
          <van-col span="6" class="primaryLabel">设备名称</van-col>
          <van-col span="18">{{ item.deviceName }}</van-col>
        </van-row>
        <van-row>
          <van-col span="6" class="primaryLabel">投入项目</van-col>
          <van-col span="18">{{ item.projectName }}</van-col>
        </van-row>
        <van-row>
          <van-col span="6" class="primaryLabel">使用时段</van-col>
          <van-col span="18" class="timeText
          ">{{ item.startTime }} - {{ item.usageStatus ? '至今' : item.endTime }} </van-col>
        </van-row>
        <van-row style="display: flex;align-items: center;">
          <van-col span="6" class="primaryLabel">使用时长</van-col>
          <van-col span="14">
            <span v-if="item.usageStatus" style="color: #FF5F4E;">使用中</span>
            <span v-else>{{ item.useTime }} 小时</span>
          </van-col>
          <van-col span="4">
            <template v-if="item.usageStatus">
              <van-button type="primary" size="mini" @click="endOfUse(item)">结束使用</van-button>
            </template>
            <template v-if="!item.usageStatus">
              <span style="color: #0283EF;">已完成</span>
            </template>
          </van-col>
        </van-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      starDate: this.getFirstDayOfMonth(),
      endDate: this.getCurrentDate(),
      starCurrentDate: new Date(),
      endCurrentDate: new Date(),
      historicalRecordsList: []
    };
  },
  mounted() {
    this.retrieveUsageRecords()
  },
  methods: {
    endOfUse(row) {
      const { deviceId, projectId, startTime, deviceLogId, deviceName } = row
      const endTime = this.getToday()
      this.$dialog.confirm({
        message: `确定结束使用【${deviceName}】设备？`
      }).then(() => {
        this.postData(`/device-log/updateDeviceUsageStatus`, { id: deviceId, projectId, startTime, endTime, deviceLogId, startOrEnd: false }).then((res) => {
          this.$toast.success('操作成功')
          this.retrieveUsageRecords()
        })
      })
    },
    retrieveUsageRecords() {
      this.postData(`/device-log/getDeviceLogList`, {
        startTime: `${this.starDate} 00:00`,
        endTime: `${this.endDate} 23:59`
      }).then((res) => {
        const { data } = res
        this.historicalRecordsList = data.map((item) => {
          return {
            ...item,
            startTime: this.formatDateTime(item.startTime),
            endTime: item.endTime ? this.formatDateTime(item.endTime) : ''
          }
        })
      })
    },
    dataStartChange(date, type, refs) {
      const one = new Date(date)
      const two = new Date(this.starDate + ' 00:00:00')
      const three = new Date(this.endDate)
      if (type == 'star') {
        if(one > three) {
          this.$toast('选择开始时间不能大于结束时间')
          return
        }
        this.starDate = this.formatDate(date)
      }
      if (type == 'end') {
        if(one < two) {
          this.$toast('选择结束时间不能小于开始时间')
          return
        }
        this.endDate = this.formatDate(date)
      }
      this.closeDataStartChange(refs)
      this.retrieveUsageRecords()
    },
    closeDataStartChange(type) {
      if (type == 'starCustom') {
        this.$refs.starCustom.toggle(false);
      } else {
        this.$refs.endCustom.toggle(false);
      }
    },
    getToday() {
      return this.formatDateTime(new Date())
    },
    formatDateTime(date) {
      const dates = new Date(date);
      return dates.toLocaleString('zh-CN', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      }).replace(/\//g, '-');
    },
    // 格式化日期
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    // 获取当月第一天 YYYY-MM-DD
    getFirstDayOfMonth() {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const firstDay = '01';
      return `${year}-${month}-${firstDay}`;
    },
    // 获取当天的日期 YYYY-MM-DD
    getCurrentDate() {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    async postData(urls, param) {
      return new Promise((resolve, reject) => {
        this.$axios.post(urls, { ...param })
          .then(res => {
            if (res.code == "ok") {
              resolve(res)
            } else {
              this.$toast.fail('获取失败:' + res.msg);
              reject(res)
            }
          }).catch(err => { this.$toast.clear(); reject(err) });
      })
    },
    back() {
      history.back();
    },
  },
};
</script>

<style lang="less" scoped>
.useRegistration {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .primaryCoverage {
    flex: 1;
    overflow-y: auto;
    box-sizing: border-box;
    padding: 0 10px;
    margin: 10px 0;
  }

  .primaryCoverageItem {
    background: #fff;
    width: 100%;
    margin-bottom: 10px;
    padding: 15px 20px;
    box-sizing: border-box;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
  }

  .timeText {
    font-size: 14px;
  }

  .primaryLabel {
    color: #999 !important;
  }

  .van-row {
    margin-bottom: 10px;

    .van-col {
      color: #333;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>